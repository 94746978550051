var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.poi_data)?_c('div',_vm._l((_vm.$store.state.poi_data),function(station,ind){return _c('div',{key:ind+'poly'},[(station.geom)?_c('div',[_c('l-geo-json',{attrs:{"options":{ style: { zIndex: 1 } },"geojson":{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: JSON.parse(station.geom),
              properties: station,
            } ],
          },"optionsStyle":{'fillColor': 'transparent','color': _vm.polygon_color[_vm.array_of_poly_values.indexOf(station.geolocation)],}}})],1):_vm._e()])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }