<template>
  
 <div >
  
  <b-overlay :show="!poi_data" rounded="sm" z-index="1001"></b-overlay>
   
 
    <!--<div style="position:absolute;top:80px;z-index:1001"> 
      <b-button  squared class="btn btn-color-sidebar btn-settings" v-b-toggle.sidebar-2 ><i class="fa-solid fa-layer-group"></i></b-button>
    </div>-->
    
    <b-sidebar  left title="ΠεΣΠΚΑ"  v-model="sidebar_settings" @change="setsidebarMap" id="sidebar-2" width="400px" bg-variant="dark" text-variant="light" >
     
      <template #header="{hide}">
        
        <div class="d-flex w-100 justify-content-between align-items-center"> 
          <p style="font-size: 1px;">`</p>
          <img src="/img/logo.jpg" style="width:35%;border-radius: 20px;"/>
         
       <div @click="hide" style="cursor: pointer;color:red;font-size: 20px;">x</div>
      </div>
     
      </template>
      <div class="py-5 text-center">
        Κατηγοριοποίηση Ακινήτων:
        <b-form-select v-model="selected_radio_group" class="mb-3">
          <!-- This slot appears above the options from 'options' prop -->
          
          <template #first>
            <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
          </template>

          <!-- These options will appear after the ones from 'options' prop -->
          <b-form-select-option v-for="(hh,index) in options_radio_group" :value="hh.value" :key="index+'select'">{{hh.text}}</b-form-select-option>
        
        </b-form-select>


      </div>
      
      <template v-slot:footer>
        <div class="text-center">
          <b-button v-b-modal.modal-profile class="mb-3" variant="primary" block >Profile<i class="fa-solid fa-user pl-2"></i></b-button>
        </div> 

        <b-modal id="modal-profile" title=""  hide-footer size="xl">
          <Profile />
        </b-modal>
        <div class="text-center">
          <b-button class="mb-3" variant="danger" block @click="Logout">Logout<i class="fa-solid fa-right-from-bracket pl-2"></i></b-button>
        </div>  
            <!--<div class="row justify-content-center align-items-center bg-white m-2 p-2 shadow" style="margin-right: 0px;margin-left: 0px;border-radius: 10px;">
              <div class="col-12 text-dark text-center">Ανάπτυξη εφαρμογής</div>
              <div class="col-4">
                <a href="https://www.adens.gr/" target="_blank"><img src="/img/logo_adens.png" style="width:100%"/></a>
              </div>
              <div class="col-4">
                <a href="http://apcg.meteo.noa.gr/" target="_blank"><img src="/img/apcg-logo.png" style="width:100%"/></a>
              </div>
              <div class="col-4">
                <a href="https://idcom.gr" target="_blank"><img src="/img/idcom-fav.png" style="width:100%"/></a>
              </div>
            </div>
            <div class="m-2">
              <img src="/img/footer-768x115.png" style="border-radius: 10px;width:100%" />
            </div>-->

      </template>

  
    </b-sidebar>
    
    <b-modal id="modal-genika-arxiaa" title="Γενικά Αρχεία" hide-footer size="lg">
      <div>
       <!-- <ul>
          <li><a href="#" @click="openPdf('apografi.pdf','genika_arxeia')"><i class="fa-solid fa-file-pdf"></i> ΑΠΟΓΡΑΦΗ ΕΚΠΟΙΗΤΕΑΣ ΠΕΡΙΟΥΣΙΑΣ ΙΕΡΑΣ ΜΗΤΡΟΠΟΛΗΣ ΖΑΚΥΝΘΟΥ - ΟΔΕΠ.pdf</a></li>
          <li><a href="#" @click="openPdf('bk_kefalonias_1996.pdf','genika_arxeia')"><i class="fa-solid fa-file-pdf"></i> ΒΚ_ΚΕΦΑΛΟΝΙΑΣ_Α.Α._1996.pdf</a></li>
          <li><a href="#" @click="openPdf('ktimatologio_1960.pdf','genika_arxeia')"><i class="fa-solid fa-file-pdf"></i> ΚΤΗΜΑΤΟΛΟΓΙΟ_Ι.ΜΟΝΗΣ_ΚΤΗΜΑΤΩΝ_ΖΑΚΥΝΘΟΥ_1960.pdf</a></li>
        </ul>-->
      </div>
    </b-modal>
    <div class="d-flex">
      
    
      <div slot="firstPane" class="w-100" style="height: 100vh;" >
          <l-map class="" style="height:100%;" :options="{ zoomControl: false}" :zoom="zoom" :center="center" ref="map1" @update:zoom="zoomUpdate">
            <l-control-zoom position="topright"  ></l-control-zoom>
      <!--<l-tile-layer :url="url"></l-tile-layer>-->
      <l-control-layers position="topright"></l-control-layers>
      <l-control position="topleft">
        <div class="p-2" style="background-color: #fff;border-radius: 20px;"><img src="/img/logo.jpg" alt="" style="width:70px;" ></div>
      </l-control>
      <l-control position="bottomleft">
        <div class="p-2" style="background-color: #fff;border-radius: 15px;"><a href="https://www.lesd.gr/" target="_blank"><img src="/img/SYSTEM-DYNAMICS-logo-3.png" alt="" style="width:60px;" ></a></div>
      </l-control>
      <l-control position="topleft" >
        <div class="p-0" title="Κατηγοριοποίηση Ακινήτων"><i class="fa-solid fa-filter p-2" style="font-size:20px"  v-b-toggle.sidebar-2 ></i> </div>
      </l-control>
      <l-control position="topleft" >
        <div class="p-0" @click="show_table=!show_table"><i class="fa-solid fa-table p-2"  style="font-size:20px"></i> </div>
      </l-control>
      <!--<l-control position="topleft" >
        <div class="p-0" title="Γενικά αρχεία" v-b-modal.modal-genika-arxia><i class="fa-solid fa-file-pdf p-2" style="font-size:25px"></i> </div>
      </l-control>-->
      <l-tile-layer
                    v-for="tileProvider in tileProviders"
                    :key="tileProvider.name"
                    :name="tileProvider.name"
                    :visible="tileProvider.visible"
                    :url="tileProvider.url"
                    :attribution="tileProvider.attribution"
                    layer-type="base"
                  />
      <l-control position="topright">
      <div
        @click="showLayerControl=!showLayerControl "
        style="width: 25px; height: 25px; border-radius: 5px; cursor: pointer;"
        class="d-flex justify-content-end align-items-center w-100"
      >
       

          <i class="fa-solid fa-k px-2" style="font-size: 20px; color: #fff;"></i>
      </div>
      <div v-if="showLayerControl">
        <h5 class="text-center" style="margin: 0 0 5px;">Χάρτες</h5>
        <div
          v-for="layer in arcgisLayers"
          :key="layer.name"
          @click="toggleLayer(layer)"
          :style="{ 
            cursor: 'pointer', 
            padding: '5px', 
            border: activearcgisLayer === layer ? '1px solid #007bff' : '1px solid transparent'
          }"
        >
        <!-- Checkbox to toggle layer visibility -->
        <input
          type="checkbox"
          :checked="activearcgisLayer === layer"
         
          style="margin-right: 10px;"
        />
          {{ layer.category }} - {{ layer.name }}
        </div>
      </div>
      </l-control>   
      <!--<v-geosearch :options="geosearchOptions"></v-geosearch>-->
      <l-control position="topright" :disableScrollPropagation="true">
       
        <div @click="show_control_search=!show_control_search" style="width: 25px;height: 25px;" class="d-flex justify-content-center align-items-center">
          <i class="fa-solid fa-magnifying-glass" style="font-size:20px;"></i>
          <i v-if="show_control_search" class="fa-solid fa-xmark"></i>
        </div>
        <b-form-input v-if="show_control_search" v-model="search_value" placeholder="Enter id" @update="change_search"></b-form-input>
        <div v-if="show_control_search && searchResults.length>0 && search_value!=='' ">
          <div style="max-width: 300px;max-height: 100px;overflow-y: scroll;">
            <li v-for="(tt,index) in searchResults" :key="index+'results'" @click="flyToMarker(tt)">{{tt.ids}} ({{ tt.akname }})</li>
          </div>
        </div>
      </l-control>
      <v-locatecontrol   :options="{position:'topright'}"/>
      <l-control position="topright" >
        <div @click="show_controls=!show_controls" style="width: 25px;height: 25px;" class="d-flex justify-content-center align-items-center"><i class="fa-solid fa-chevron-down" style="font-size:20px;"></i></div>
        
      </l-control>
      <l-control position="topleft" >
        <div class="p-1" title="Στατιστικά" v-b-modal.modal-genika-arxia>
          <img src="/img/metabase.svg" style="width: 28px;"  /></div>
      </l-control> 
      <l-control-polyline-measure
        v-if="show_controls"
        :options="{
          showUnitControl: true,
          showClearControl: true,
          clearMeasurementsOnStop: false,
          }"
        position="topright"
        />
        <l-control-scale
        v-if="show_controls"
                    position="topright"
                    :imperial="true"
                    :metric="true"
        ></l-control-scale>
      <!--<l-wms-tile-layer
                      :base-url="'https://geoserver.idcom.gr/geoserver/perifereia/wms'"
                      :layers="'perifereia:geodata_dimoi_attikis_kallikratis'"
                      :transparent="true"
                      format="image/png"
                      :zIndex="7"
                      :opacity="1"
                    >
      </l-wms-tile-layer>
    37.98750437106374; // Replace with your minimum latitude
    const maxLat = 38.136716904135376; // Replace with your maximum latitude
    const minLng = 23.36105346679688; // Replace with your minimum longitude
    const maxLng = 23.68034362792969;
    --> 
   
      
    
      <div v-for="(station,ind) in poi_data" :key="ind+'stations'"> 
        <l-marker :lat-lng="[station.lat,station.lon]" ref="marker" :name="station.ids.toString()" @click="markerClick(station)">
        
          <l-icon :icon-anchor="[15, 15]" v-if="selected_radio_group==='geolocation'">
            <div style="">
                <div v-if="live_zoom>12" class="px-1" style="position:absolute;top:-10px;left:-10px; z-index: 1002;border-radius: 20px; color: rgb(0, 0, 0);font-size: 12px; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: rgb(3, 3, 3);background-color: #fff;">{{ station.ids }}</div>
                
                <img :src="'/img/geolocation/'+ string_to_slug(station.geolocation)+'.png'" style="width:20px;position:absolute;top:4px;left:3px;font-size: 12px;color:#fff;"/>
            </div>
          </l-icon>
          <l-icon :icon-anchor="[15, 15]" v-if="selected_radio_group!=='geolocation'">
            <div style="">
                <div v-if="live_zoom>12" class="px-1" style="position:absolute;top:-15px;left:-15px; z-index: 1002;border-radius: 20px; color: rgb(22, 22, 22);font-size: 13px; -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: rgb(0, 0, 0);background-color: #fff; ">{{ station.ids }}</div>
                <i class="fa-solid fa-location-pin"  :style="{'color':options_radio_group[orderSelected.indexOf(selected_radio_group)][station[selected_radio_group]].color,'font-size':'20px','border':'1rem solid;'}" ></i>
            </div>
          </l-icon>
          <l-tooltip>{{ station.akname }}</l-tooltip>
          <l-popup >
            <b-button class="mb-2" @click="openTautotita(station)" size="sm">
              Ταυτότητα <i class="fa-solid fa-up-right-from-square"></i>
            </b-button>
           
            <br>
            <strong style="font-size: 20px;">Επισκόπηση Ακινήτου</strong> 
            <table class="table table-bordered table-sm table-responsive" style="max-height: 350px;min-width: 345px;">
              <tbody >
                <tr v-for="(col,index) in table_columns" :key="index+'table_col'">
                  <td>{{col.text }}</td>
                  
                  
                  <td v-if="station[col.value]!=='' && station[col.value] !== false && station[col.value] !== true">{{ station[col.value] }}</td>
                  <td v-if="typeof station[col.value] === 'boolean'"> <b-form-checkbox v-model="station[col.value]" name="checkbox-1" disabled></b-form-checkbox></td>
                  <td v-if="station[col.value]===''">-</td>      
                </tr>
                <tr>
                  <td>Πληροφοριακό έντυπο</td>
                  <td class="d-flex"><i class="fa-solid fa-file-pdf" @click="openPdfEntipa(station,'pliroforiaka_entipa')" style="cursor:pointer;font-size: 25px;color:red"></i> <div v-if="tst"><b-spinner label="Spinning" small></b-spinner></div> </td>
                </tr>
                <tr>
                  <td>Πίνακας μισθώσεων</td>
                  <td class="d-flex"><i class="fa-solid fa-file-pdf" @click="openPdfEntipa(station,'misthotiria')" style="cursor:pointer;font-size: 25px;color:red"></i> <div v-if="tst"></div> </td>
                </tr>
              </tbody>
            </table>
          </l-popup>
        </l-marker>

        <!--<div v-if="station.geom">
          <l-geo-json 
          :options="{ style: { zIndex: 1 } }"
          :geojson="{
                                type: 'FeatureCollection',
                                features: [
                                  {
                                    type: 'Feature',
                                    geometry: JSON.parse(station.geom),
                                    properties: station,
                                  },
                                ],
                              }"
                         
                              
              ></l-geo-json>
        </div>-->
      </div>
          
      <!--<div v-for="(point,ind) in poly_vertices" :key="ind+'point'"> 
        <l-circle
          ref="circle_poly_vertices"
          :options="{ zIndex: 2 }"
          :lat-lng="[JSON.parse(point.geom).coordinates[1],JSON.parse(point.geom).coordinates[0]]"
          :radius="2"
          :color="'green'"
          
        />
      </div>-->
      <ParcelsPoly :live_zoom="live_zoom"  v-if="live_zoom>15"></ParcelsPoly>
      <polyVertices :live_zoom="live_zoom" :id_polygon="station_clicked" v-if="live_zoom>15"></polyVertices>
     
    </l-map>

    <b-modal id="modal-genika-arxia" title="" hide-footer size="xl" class="p-0">
      
      <b-tabs content-class="h-100" class="" style="height:80vh">
        <iframe width="100%" style="height:100%" :src="'https://metabase.systemsdynamics.ddns.net/public/dashboard/1760b477-543e-4ae9-8e22-ea8f3f86b003'"></iframe>
 
      </b-tabs>
    </b-modal>
    <!--<div class="legend-div" style="" v-if="current_legend">
        <div class="card shadow" style="border-radius: 20px;">
          <div class="card-body">
            <legendControl :data="current_legend"></legendControl>        
          </div>
        </div>
      </div>-->
      <div v-if="poi_data && show_table" class="p-3" style="position: absolute;bottom:0;left:0;width:100%; z-index: 1002;">
        <div class="p-2" style="background-color: #fff;border-radius: 15px;">
          <b-table bordered small sticky-header striped hover :items="poi_data" :fields="table_fields" style="font-size: 12px;" @row-clicked="tableToMarker">
            <template #cell()="data">
                      
                      <div v-if="data.value !== '' && data.value !== false && data.value !== true">{{ data.value }}</div>
                      <div v-if="typeof data.value === 'boolean'"> <b-form-checkbox v-model="data.value" name="checkbox-1" disabled></b-form-checkbox></div>
                      <div v-if="data.value===''">-</div>
                    </template>
          </b-table>
        </div>
      </div>
     
      <div style="position: absolute;bottom:40px;right:20px; z-index: 1001;" v-if="selected_radio_group">
          <legendControl :data="options_radio_group[orderSelected.indexOf(selected_radio_group)]" ></legendControl>
      </div>
     
        <div style="position: absolute;top:20px;right:20px; z-index: 1001;" v-draggable="{ handle: 'strong' }" v-if="show_tabs">
            <div class="rounded text-right" style="background-color:#003b5dbf ;">
              <b-button v-if="!visible_tabs" @click="visible_tabs = !visible_tabs" class="m-1"><i class="fa-solid fa-window-maximize"></i></b-button>
              <b-button v-if="visible_tabs" @click="visible_tabs = !visible_tabs" class="m-1" variant="danger"><i class="fa-solid fa-xmark"></i></b-button>
              <strong> <i class="fa-solid fa-up-down-left-right px-4 text-light" style="cursor: grab;"></i> </strong>
            </div>
            <b-collapse id="collapse-2" v-model="visible_tabs" >
              <b-card no-body style="height:40vh;overflow-y: scroll;width: 80vw;"> 
                <b-tabs card v-if="tabs_info">
                  <div v-for="(item, index) in tabs_info" :key="index+'tabs'">
                    <b-tab :title="item.tab_name">
                      <b-card-text >
                        <div v-for="(table,ind) in item.tables" :key="ind+'table_tab'" >
                          <div style="font-weight: 700;">{{ table.table_name }}</div> 
                          <b-table stacked  bordered small sticky-header striped hover :items="station_clicked" :fields="table.fields" style="font-size: 12px;">
                            <template #cell()="data">
                      
                              <div v-if="data.value !== '' && data.value !== false && data.value !== true">{{ data.value }}</div>
                              <div v-if="typeof data.value === 'boolean'"> <b-form-checkbox v-model="data.value" name="checkbox-1" disabled></b-form-checkbox></div>
                              <div v-if="data.value===''">-</div>
                            </template>
                          </b-table>
                        </div>

                      </b-card-text>
                       
                    </b-tab>
                  </div>
                  <b-tab title="Έγγραφα Ακινήτου">
                      <div class="row">
                      <div class="col-4" >
                        <b-form-select v-model="selected_file" :select-size="13" @change="changeSelectedFile">
                          <b-form-select-option :value="index" v-for="(item,index) in file_list" :key="index+'file_list'">{{ item.doctype }} ({{ item.iddoc }})</b-form-select-option>
                        </b-form-select>
                        <!--<div  v-for="(item,index) in file_list" :key="index+'file_list'">
                          
                           <b-button squared block  variant="outline-secondary" style="text-align: start!important;"> {{ item.iddoc }} - {{ item.doctype }}</b-button>
                        </div>-->
                      </div>
                      <div class="col-8">
                          <div v-if="selected_file !==null">
                            <b-table stacked  bordered small sticky-header striped hover :items="[file_list[selected_file]]" :fields="file_list_fields" style="font-size: 12px;">
                              <template #cell()="data">
                      
                              <div v-if="data.value !== '' && data.value !== false && data.value !== true">{{ data.value }}</div>
                              <div v-if="typeof data.value === 'boolean'"> <b-form-checkbox v-model="data.value" name="checkbox-1" disabled></b-form-checkbox></div>
                              <div v-if="data.value===''">-</div>
                            
                            </template>
                            <template #cell(docfilename)="data">
                                <div>{{data.item.docfilename}}</div>
                                <div><i class="fa-solid fa-file" @click="openPdf(data.item.docfilename,'eggrafa_akiniton')" style="cursor:pointer;font-size: 20px;color:red"></i></div>
                            </template>
                            </b-table>
                           
                          </div>
                      </div>
                      </div>
                  </b-tab>
                </b-tabs>
              </b-card>
            </b-collapse>
          </div>
      </div>
    </div>
 </div>
</template>


<script>
//import L from "leaflet";

import axios_django from "../axios-auth";
//import axios_all from "axios";
//import VJstree from "vue-jstree";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
//import axios from 'axios'
//import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import 'vue-slider-component/theme/default.css'
//import legend_data from '../../public/json/legend_data.json'
import table_columns from '../json/table_columns.json'
import filter_columns from '../json/filter_columns.json'
import tabs_info from '../json/tabs.json'
import legendControl from './legendBari.vue'
import polyVertices from './Poly_Vertices.vue'
import ParcelsPoly from './Parcels_Poly.vue'
import Profile from './Profile.vue'

//import VGeosearch from 'vue2-leaflet-geosearch';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Vue2LeafletLocatecontrol from 'vue2-leaflet-locatecontrol'
//import ResSplitPane from 'vue-resize-split-pane'
import "../js/L.Map.Sync.js";
import "../js/leaflet-polygon.fillPattern.js";
//import "../js/esri/EsriLeaflet.js"; // Import Esri-Leaflet for FeatureServer support
//import {Draggable } from "vue-smooth-dnd";
//import { applyDrag } from "../utils";
import * as esri from "esri-leaflet";

export default {
  components: {
    //VueSlider,
    //VJstree,
    //'rs-panes':ResSplitPane,
    legendControl,
    "l-control-polyline-measure": LControlPolylineMeasure,
    //VGeosearch,
    'v-locatecontrol': Vue2LeafletLocatecontrol,
    //Container, 
    //Draggable,
    polyVertices,
    ParcelsPoly,
    Profile
  },
  data() {
    return {
      showLayerControl:false,
      visible_tabs:true,
      show_tabs:false,
      file_list:null,
      selected_file:null,
      station_clicked:null,
      tabs_info:tabs_info,
      orderSelected:[],
      show_table:false,
      tst:false,
      search_value:'',
      searchResults:[],
      show_control_search:false,
      show_controls:false,
      poi_data:null,
      poly_vertices:null,
      table_columns:table_columns,
      filter_columns:filter_columns,
      sidebar_settings:this.$store.state.sidebarMap,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      stationsAllData:null,
      zoom: 9,
      center: [37.77384340624945, 20.872762347234783],
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "OpenStreetMap.HOT",
          visible: false,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
          url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        },

        {
          name: "OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: "Stadia.AlidadeSmoothDark",
          visible: false,
          url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=9a9206be-79a9-461b-986f-f9b419cdb7ea",
          attribution:
            '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        },
        {
          name: "CyclOSM",
          visible: false,
          url: "https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
          attribution:
            '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: "Esri.WorldImagery",
          visible: true,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        }
        
      ],
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        style: 'button',
        position:'topright'
      },
      table_fields:[],
      selected_radio_group:null,
      options_radio_group:[
      ],
      live_zoom:9,
      file_list_fields:[
                  {
                      "label":"Κωδικός Εγγράφου",
                      "key":"iddoc"
                  },
                  {
                      "label":"Κωδικός Συστήματος Ακινήτου",
                      "key":"ids"
                  },
                  {
                      "label":"Προσωρινός Κωδικός",
                      "key":"proptempid"
                  },
                  {
                      "label":"αρ. πρωτοκόλλου",
                      "key":"docprot"
                  },
                  {
                      "label":"Τίτλος Εγγράφου",
                      "key":"doctitle"
                  },
                  {
                      "label":"Αριθμός Εγγράφου",
                      "key":"docnum"
                  },
                  {
                      "label":"Ονοματεπώνυμο προσώπου που επικυρώνει",
                      "key":"docepname"
                  },
                  {
                      "label":"Ιδιότητα προσώπου που επικυρώνει",
                      "key":"docepprof"
                  },
                  {
                      "label":"Θέση προσώπου που επικυρώνει",
                      "key":"doceppos"
                  },
                  {
                      "label":"Οργανισμός που επικυρώνει",
                      "key":"doceporg"
                  },
                  {
                      "label":"Ημερομηνία εγγράφου",
                      "key":"docdate"
                  },
                  {
                      "label":"Ονομασία αρχείου εγγράφου",
                      "key":"docfilename"
                  },
                  /*{
                      "label":"το έγγραφο αφορά σε πειουσιακό στοιχείο εκτός περιουσίας",
                      "key":"noproperty"
                  },*/
                  {
                      "label":"ονομασία φακέλου αρχείου φορέα",
                      "key":"archfold"
                  }
      ],
      arcgisLayers: [
      {
        category: "Δασικός Χάρτης",
        name: "DX_KYROSH",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/DASIKA_KYROSH_P_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}


      },
      {
        category: "Δασικός Χάρτης",
        name: "DX_ANARTHSH",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/DASIKA_ANARTHSH_P_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}
      },
      {
        category: "Κτηματολόγιο",
        name: "PROKATARKTIKA",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_PROKATARKTIKA_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      {
        category: "Κτηματολόγιο",
        name: "PROANARTHSH",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_PROANARTHSH_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      {
        category: "Κτηματολόγιο",
        name: "ANARTHSH",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_ANARTHSH_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      {
        category: "Κτηματολόγιο",
        name: "LEITOURGOUN",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_LEITOURGOUN_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      {
        category: "Κτηματολόγιο",
        name: "APOKLEISTIKES",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_APOKLEISTIKES_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      {
        category: "Κτηματολόγιο",
        name: "DOULEIES",
        url: "https://services-eu1.arcgis.com/40tFGWzosjaLJpmn/ArcGIS/rest/services/GEOTEMAXIA_DOULEIES_ON_gdb/FeatureServer/0",
        visible: false,
        drawingInfo:{"renderer":{"type":"simple","symbol":{"type":"esriSFS","style":"esriSFSSolid","color":[76,129,205,191],"outline":{"type":"esriSLS","style":"esriSLSSolid","color":[0,0,0,255],"width":0.75}}},"transparency":0,"labelingInfo":null}

      },
      ],
      activearcgisLayer: null,
    }
  },
 
  mounted(){
    console.log(esri)
    this.$nextTick(() => {
      
  
      //this.$refs.map1.mapObject.sync(this.$refs.map2.mapObject)
      //this.$refs.map2.mapObject.sync(this.$refs.map1.mapObject)
      const map = this.$refs.map1.mapObject;
      

      // Initialize Leaflet layers
      this.arcgisLayers.forEach((layer) => {
        // Extract drawingInfo for styles
        const renderer = layer.drawingInfo.renderer;
        const symbol = renderer.symbol;

        // Helper function to convert ArcGIS RGBA to CSS-compatible rgba()
        const rgbaToCss = (rgba) =>
          `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3] / 255})`;

        // Create the layer with custom styles
        layer.leafletLayer = esri.featureLayer({
          url: layer.url,
          style: function () {
            return {
              // Fill color and opacity
              fillColor: rgbaToCss(symbol.color),
              fillOpacity: symbol.color[3] / 255,
              // Outline color and weight
              color: rgbaToCss(symbol.outline.color),
              weight: symbol.outline.width,
            };
          },
        });
        // Add visible layers to the map initially
        if (layer.visible) {
          layer.leafletLayer.addTo(map);
        }
      });

    });
  },
  async created(){
   
    this.loadchurch()
    //this.loadPolyVertices()
    for(var gg=0; gg<this.table_columns.length; gg++){
      this.table_fields.push({
        key: this.table_columns[gg].value,
        label: this.table_columns[gg].text,
        //sortable: true,
      })
    }
    console.log(this.table_fields)

  
  },
  methods:{
    toggleLayer(layer) {
      const map = this.$refs.map1.mapObject;
      console.log(layer)
      // Remove the currently active layer if present
      if (this.activearcgisLayer) {
        this.activearcgisLayer.leafletLayer.removeFrom(map);
      }

      // Add the selected layer to the map
      if (layer !== this.activearcgisLayer) {
        layer.leafletLayer.addTo(map);
        this.activearcgisLayer = layer; // Set the selected layer as active
      } else {
        this.activearcgisLayer = null; // Deselect if the same layer was clicked
      }
    },
    string_to_slug(str) {

    str  = str.replace(/^\s+|\s+$/g, '') // TRIM WHITESPACE AT BOTH ENDS.
            .toLowerCase();            // CONVERT TO LOWERCASE

    const from = [ "ου", "ΟΥ", "Ού", "ού", "αυ", "ΑΥ", "Αύ", "αύ", "ευ", "ΕΥ", "Εύ", "εύ", "α", "Α", "ά", "Ά", "β", "Β", "γ", "Γ", "δ", "Δ", "ε", "Ε", "έ", "Έ", "ζ", "Ζ", "η", "Η", "ή", "Ή", "θ", "Θ", "ι", "Ι", "ί", "Ί", "ϊ", "ΐ", "Ϊ", "κ", "Κ", "λ", "Λ", "μ", "Μ", "ν", "Ν", "ξ", "Ξ", "ο", "Ο", "ό", "Ό", "π", "Π", "ρ", "Ρ", "σ", "Σ", "ς", "τ", "Τ", "υ", "Υ", "ύ", "Ύ", "ϋ", "ΰ", "Ϋ", "φ", "Φ", "χ", "Χ", "ψ", "Ψ", "ω", "Ω", "ώ", "Ώ" ];
    const to   = [ "ou", "ou", "ou", "ou", "au", "au", "au", "au", "eu", "eu", "eu", "eu", "a", "a", "a", "a", "b", "b", "g", "g", "d", "d", "e", "e", "e", "e", "z", "z", "i", "i", "i", "i", "th", "th", "i", "i", "i", "i", "i", "i", "i", "k", "k", "l", "l", "m", "m", "n", "n", "ks", "ks", "o", "o", "o", "o", "p", "p", "r", "r", "s", "s", "s", "t", "t", "y", "y", "y", "y", "y", "y", "y", "f", "f", "x", "x", "ps", "ps", "o", "o", "o", "o" ];

    for ( var i = 0; i < from.length; i++ ) {

      while( str.indexOf( from[i]) !== -1 ){

          str = str.replace( from[i], to[i] );    // CONVERT GREEK CHARACTERS TO LATIN LETTERS

      }

    }

    str = str.replace(/[^a-z0-9 -]/g, '') // REMOVE INVALID CHARS
            .replace(/\s+/g, '-')        // COLLAPSE WHITESPACE AND REPLACE BY DASH - 
            .replace(/-+/g, '-');        // COLLAPSE DASHES
    
    //console.log(str)
    return str;

    },
    openTautotita(station){
      this.markerClick(station)
      this.show_tabs=true
      this.visible_tabs=true
    },
    changeSelectedFile(){
    
      console.log(this.file_list[this.selected_file])

    },
    markerClick(station){
      this.station_clicked=[station]
      console.log(this.station_clicked)
      var token=localStorage.getItem('token')
      axios_django.get('sydapzak/file_list/',{params:{ids:this.station_clicked[0].ids},headers: {Authorization: `Bearer ${token}`}}).then(res=>{
        console.log(res.data)
        this.file_list=res.data
      })

      this.show_tabs=false
    },  
    loadPolyVertices(){
      var token=localStorage.getItem('token')
      axios_django.get('sydapzak/poly_vetrices/',{headers: {Authorization: `Bearer ${token}`}}).then(res=>{
        this.poly_vertices=res.data
        console.log(this.poly_vertices)
      })
    },
    loadchurch(){
      var token=localStorage.getItem('token')

      axios_django.get('sydapzak/poi/',{headers: {Authorization: `Bearer ${token}`}}).then(res=>{
        console.log(res.data)
        this.poi_data=res.data
        var obj=[]
        obj=this.filter_columns
        var array_colors=[]
        var unique_color
        //for(var uu=0; uu<this.table_columns.length; uu++){
        //  if(this.table_columns[uu].in_radio_group){
        //    obj.push(this.table_columns[uu])
        //  }
        //}
        for(var tt=0; tt<obj.length; tt++){
          for(var ll=0; ll<res.data.length; ll++){
          
            if(obj[tt][this.poi_data[ll][obj[tt].value]]){
              obj[tt][this.poi_data[ll][obj[tt].value]].number++
            }else{
              obj[tt][this.poi_data[ll][obj[tt].value]]={}
              obj[tt][this.poi_data[ll][obj[tt].value]].number=1
              //unique_color=this.generateUniqueColor(array_colors);
              if(obj[tt].value==='geolocation'){
                obj[tt][this.poi_data[ll][obj[tt].value]].icon= obj[tt].value+'/'+ this.string_to_slug(this.poi_data[ll][obj[tt].value])+'.png'
              }else{
                unique_color=this.generateUniqueColor(array_colors);
                obj[tt][this.poi_data[ll][obj[tt].value]].color=unique_color
                array_colors.push(unique_color)
              }
              //array_colors.push(unique_color)
            }
          }
          this.orderSelected.push(obj[tt].value)
        }
        console.log(obj)
        console.log(this.orderSelected)
        this.options_radio_group=obj
        this.selected_radio_group=obj[0].value
      })  
    },
    setsidebarMap(){
      //console.log(this.sidebar_settings)
      this.$store.commit('set', ['sidebarMap', this.sidebar_settings])
    },
    change_search(){
      this.searchByName(this.search_value);
    },
    searchByName(searchStr) {
    searchStr = searchStr.toLowerCase(); // Convert search string to lowercase for case-insensitive search
    //console.log(this.poi_data)
    
    this.searchResults = this.poi_data.filter(item => item.ids.toString().toLowerCase().includes(searchStr));
    console.log(searchStr)
    },
    flyToMarker(marker){
      this.$refs.map1.mapObject.flyTo([marker.lat,marker.lon],18)
      //this.$refs.marker[marker.id-1].mapObject.openPopup()
      console.log(marker)
      console.log(this.$refs.marker[1])
      const indexOf = this.$refs.marker.findIndex(item => item.name === marker.ids.toString());
      console.log(indexOf)
      this.$refs.marker[indexOf].mapObject.openPopup()
      this.show_tabs=false
      this.station_clicked=[marker]
    },
    generateUniqueColor(existingColors) {
      let newColor;

      do {
        newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      } while (existingColors.includes(newColor));

      return newColor;
    },
    openPdfEntipa(station,folder){
      var str_id
      
      if(station.ids<10){
        str_id='00'+station.ids
      }else if(9<station.ids && station.ids<100){
        str_id='0'+station.ids
      }else{
        str_id=station.ids
      }

      var name=str_id+'_'+folder+'.pdf'
      //name='p13.pdf'
      this.openPdf(name,folder)
      console.log(name)
    },
    openPdf(name,folder){
      var v=this
      this.tst=true
      var token=localStorage.getItem('token')
      axios_django
            .get("sydapzak/view_pdf/", { params: { name: name,folder:folder }, responseType: "blob",headers: {Authorization: `Bearer ${token}`} })
            .then((response) => {
              //Create a Blob from the PDF Stream
              ////console.log(name.includes('.jpg'))
              var type;
              type = !name.includes('.jpg') ? "application/pdf": 'image/jpeg';
              
              ////console.log(name)
              const file = new Blob(
                [response.data],
                //{type: 'application/pdf'});
                { type: type }
              );
              ////console.log(file)
              //Build a URL from the file

              const fileURL = URL.createObjectURL(file);
              //Open the URL on new Window

              window.open(fileURL);
              
              v.tst=false
            });
    },
    tableToMarker(item){
      //console.log(item)
      this.flyToMarker(item)
    },
    zoomUpdate(zoom){
      //console.log(zoom)
      this.live_zoom=zoom
    },
    Logout(){
      localStorage.clear()
      this.$router.push('/')
    }
    
    
  
  }
 

};
</script>

<style >

.tree-default .tree-node {
    margin-left: 10px!important;
}
.tree-default .tree-wholerow-clicked {
    background: '#fff0'!important;
}
.tree-default .tree-disabled > .tree-icon {
   opacity: 1!important;
  filter: 0 !important;
  filter: rgba(128, 128, 128, 0) !important;
  -webkit-filter: grayscale(0%) !important;
}
.tree-default .tree-disabled{
  color:inherit!important;
}

.tree-default .tree-disabled > .tree-checkbox {
  opacity: 0.05!important;
}
.tree-default .tree-wholerow-clicked {
    background: #fff0!important;
}
.tree-wholerow-hovered{
  background: #fff0!important;
}
.bottomleft-control {
  background: #6c757d;
    border-radius: 0 20px 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.logo-perifereia {
  width: 20vh;
}
.chart-div{
  border-radius: 20px 0 0 0;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
  width:35%;
}
.btn-settings{
  border-radius: 0 20px 20px 0!important;
  box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
}
.legend-div{
  position:absolute;
  bottom:8px;
  right:5px;
  z-index: 1001;
}
@media screen and (max-width: 730px) {
  .chart-div{
    border-radius: 0 0 0 0;
    box-shadow: 0 0 3rem rgb(0 0 0/45%)!important;
    width:100%;
  }
  .logo-perifereia {
  width: 15vh;
}

.card-body {
   
    padding: 0.4rem!important;
}
}
hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
/*.tree-children{
  padding:10px!important;
}*/

::-webkit-scrollbar {
    width: 5px;
    height:5px
}
::-webkit-scrollbar-thumb {
    background: #07a4ff;
    border-radius: 20px;
}
::-webkit-scrollbar-track {
    background-color: #9e9e9e;
    border-radius: 20px;    
}
.leaflet-control{
  background:#003b5dbf!important;
  border-radius:20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
  padding:10px;
  color:white
}
.leaflet-touch .leaflet-bar a{
  background: #fff0!important;
  color: #fff;
  width: 25px;
  height: 25px;

}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
    border: 0px solid rgba(0,0,0,0.2)!important;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 5px 15px;
}
.leaflet-touch .leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
}
.leaflet-control-geosearch a.leaflet-bar-part:after {
    top: 16px;
    left: 16px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.leaflet-control-geosearch a.leaflet-bar-part:before {
    top: 29px;
    left: 26px;
    width: 8px;
    border-top: 2px solid #fff;
    transform: rotateZ(45deg);
    
}
.fa-map-marker{
  font-size:20px;
  color:#fff
}
.leaflet-tooltip-own{
  background:#ffffff30;
  border: 0px solid #ffffff30;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    }

.geosearch{
  color:black;
  z-index:9000;
}
.col-lg-12{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-6{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-7{
  padding-right: 0px!important;
  padding-left: 0px!important;
}
.col-lg-5{
  padding-right: 0px!important;
  padding-left: 0px!important;
}



.gradient-box {
  
  background: linear-gradient(45deg, #810170, #001691, rgb(0, 100, 95)); /* Initial gradient colors */
  background-size: 600% 600%;
  animation: changeGradient 20s infinite;
}

@keyframes changeGradient {
  0% {
    background-position: 0% 50%; /* Initial position */
  }
  50% {
    background-position: 100% 50%; /* Final position */
  }
  100% {
    background-position: 0% 50%; /* Back to initial position */
  }
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  width: 380px;
}
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
    text-align: left!important;;
}
</style>



<!--
  {
                "text": "M.A. θερμών ημερών 1981-2000",
                "description":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)",
                "type_of_data":"geojson",
                "loaded":false,
                "data_column":"Be46",
                "value":[{"api":"pespka/klimatikes_allages/","params": {"db_name":"be46"}}],
                "legend":{"tooltip":{"show":true,"tooltip_column":"mean","permanent":true},"column":"mean", "value":[[0,114],[114,117],[117,124],[124,130],[130,132]],"color":["#ffcccc","#ff9e8f","#fa725a","#ed432d","#db0000"],"text":["0-113","114-117","118-124","125-130","131-132"],"title":"Μέσος αριθμός πολύ θερμών ημερών (>35°C) την περίοδο αναφοράς (1981-2000)","weight":0.5,"weight_color":"black"}
            },
-->